.aboutArticle {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: $pad * 3;
    margin: 0 auto;
  }

  & .accentBarAfter {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -2;
    width: 60%;
    height: 100%;
    max-height: $pad * 60;
    transform: translate(-50%, -50%);
  }

  & .accentBarAfter::after {
    width: 100%;
    max-width: 100%;
    height: 100%;
    transform: scaleY(0);
    animation: barVertical 300ms 300ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
      forwards;
  }

  & .titleAndInfo {
    max-width: 67 * $pad;
  }

  & header {
    margin-bottom: $pad * 2;

    & h2 {
      position: relative;
      display: inline-block;
      animation: glitchFadeIn 300ms 400ms ease forwards;
      opacity: 0;
    }

    & h2::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10%;
      height: 30%;
      width: 100%;
      background-image: url("../assets/img/crossPatern.png");
      opacity: 0;
      animation: glitchFadeInKwarter 500ms 500ms ease forwards;
    }
  }

  & p {
    margin-bottom: $pad * 2;
  }

  & header p {
    padding: 0;
    animation: glitchFadeIn 300ms 500ms ease forwards;
    opacity: 0;
  }

  & p:last-of-type {
    margin-bottom: $pad * 2;
  }

  & .aboutInfo {
    animation: glitchFadeIn 300ms 600ms ease forwards;
    opacity: 0;
  }

  & .imageHolder {
    z-index: -1;
    order: -1;
    width: 80%;
    max-width: $pad * 45;
    height: $pad * 40;
    margin: 0 0 -10% -40%;
  }

  & .imageHolder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: glitchFadeIn 300ms 400ms ease forwards;
    opacity: 0;
  }

  & .aboutDeco1,
  & .aboutDeco2 {
    display: none;
  }
}

@media only screen and (min-width: 1070px) {
  .aboutArticle {
    padding-top: 7.1rem;

    & .wrapper {
      flex-direction: row;
      align-items: flex-start;
    }

    & .titleAndInfo {
      position: relative;
      align-self: center;
      flex: 3;
      max-width: $pad * 70;
    }

    & .panel.titleAndInfo {
      padding: $pad * 8 $pad * 8 $pad * 5;
    }

    & .panel.imageHolder {
      padding: $pad * 8 $pad * 5 $pad * 5;
    }

    & header {
      padding: 0;
      margin: 0 0 $pad * 3;
      align-self: flex-end;

      & h2 {
        font-size: $pad * 5;
      }

      & p {
        font-size: $pad * 3;
      }
    }

    & p {
      padding: 0;
    }

    & .imageHolder {
      top: $pad * 3;
      height: $pad * 63;
      margin: 0;
      margin-right: -$pad * 3;
    }

    & .aboutDeco1 {
      flex: 2;
      z-index: -1;
      margin-left: -$pad * 5;
      display: block;
      max-width: $pad * 45;
      height: $pad * 42;
      align-self: center;
      transform: translateY($pad * 8);

      & div {
        height: 100%;
        background-image: url("../assets/img/crossPaternFade.png");

        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url("../assets/img/foText.svg");
          background-size: 100% auto;
          margin: $pad * 7 $pad * 5 $pad * 5;
        }
      }
    }

    & .aboutDeco2 {
      display: block;
      bottom: -50%;
      right: $line;
      width: 100%;
      height: $pad * 22;

      & div {
        height: 100%;
        background-image: url("../assets/img/crossPaternFade.png");
      }
    }

    & .accentBarAfter {
      margin-top: $pad * 5;
      width: $pad * 80;
    }

    & .defaultButton {
      display: inline-block;
    }
  }
}
