.homeArticle {
  height: 100%;

  & .wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: $pad * 3;
    max-width: $pad * 75;
    margin: 0 auto;
  }

  & .panel {
    z-index: 2;
    padding: $pad * 11 $pad * 3 $pad * 5;
  }

  & .accentBarAfter::after {
    z-index: -2;
    top: -$pad * 3;
    right: $pad * 6;
    bottom: -$pad * 2;
    width: 55vw;
    max-width: $pad * 40;
    animation: barVertical 300ms 300ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
      forwards;
    transform: scaleY(0);
  }

  & header {
    & h2 {
      position: relative;
      display: inline-block;
      animation: glitchFadeIn 300ms 400ms ease forwards;
      opacity: 0;
    }

    & h2 span {
      display: block;
    }

    & h2::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -15%;
      height: 60%;
      width: 100%;
      background-image: url("../assets/img/crossPatern.png");
      opacity: 0;
      animation: glitchFadeInKwarter 500ms 500ms ease forwards;
    }

    & p {
      animation: glitchFadeIn 300ms 500ms ease forwards;
      opacity: 0;
    }
  }

  & .panel.logo {
    padding: $pad * 6 $pad * 3 $pad * 3;
    margin-bottom: -$pad * 6;
    margin-top: $pad * 6;
    margin-left: -$pad * 4;
    align-self: flex-start;

    & img {
      width: $pad * 15;
      height: $pad * 15;
      animation: glitchFadeIn 300ms 500ms ease forwards;
      opacity: 0;
    }
  }

  & .shortInfo {
    margin-top: $pad;
    font-weight: bold;
    animation: glitchFadeIn 300ms 550ms ease forwards;
    opacity: 0;
  }

  & .scrollNextPage {
    margin-top: $pad * 3;
  }

  & .panel.skills {
    position: relative;
    z-index: 5;
    order: 1;
    margin-bottom: -$pad * 23;
    margin-top: $pad * 4;
    margin-left: $pad * 16;
    font-size: $pad * 2;
    padding: $pad * 5 $pad * 2 $pad * 2;
    align-self: flex-start;
    max-width: 80%;

    & .carroucel {
      width: 100%;
      overflow: hidden;
    }

    & .carroucelContent {
      display: flex;
      width: 1000vw;
    }

    & .carroucelContent:after {
      content: attr(data-loop);
      display: block;
      white-space: pre;
      animation: moveWords 10s infinite linear;
    }

    & ul {
      display: flex;
      animation: moveWords 10s infinite linear;
    }

    & li::after {
      content: " - ";
      white-space: pre;
    }
  }

  .homeDeco1 {
    position: absolute;
    top: -$pad * 23;
    bottom: -$pad * 6;
    left: 100%;
    width: $pad * 50;
    margin-left: -$pad * 6;

    & .decoPanel {
      position: relative;
      height: 100%;
    }

    & .decoPanel div {
      position: relative;
      height: 100%;
      background-image: url("../assets/img/crossPaternFade.png");

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../assets/img/foText.svg");
        background-size: 100% auto;
        margin: $pad * 5;
      }
    }
  }
}

@keyframes moveWords {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (min-width: 1070px) {
  .homeArticle {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 7.1rem;

    & .wrapper {
      height: auto;
      max-width: $pad * 100;
    }

    & .panel {
      padding: $pad * 10 $pad * 8 $pad * 9;
    }

    & p {
      font-size: $pad * 3;
      line-height: initial;
    }

    & .accentBarAfter::after {
      top: -$pad * 3;
      left: $pad * 8;
      bottom: -$pad * 10;
      width: 100%;
      max-width: 100%;
    }

    & .shortInfo {
      margin-top: $pad * 2;
    }

    & .scrollNextPage {
      margin-top: $pad * 5;
    }

    & .defaultButton {
      padding: $pad * 2 $pad * 3;
      display: inline-block;
    }

    & .panel.logo {
      padding: $pad * 6 $pad * 3 $pad * 3;
      margin-bottom: -$pad * 33;
      margin-left: -$pad * 28;
      margin-top: -$pad * 3;

      & img {
        width: $pad * 25;
        height: $pad * 25;
      }
    }

    & .panel.skills {
      order: -1;
      margin-bottom: 0;
      margin-top: -$pad * 6;
      margin-left: -$pad * 8;
      font-size: $pad * 3;
      padding: $pad * 5 $pad * 4 $pad * 2;
    }

    & .homeDeco1 {
      top: -$pad * 14;
      bottom: -$pad * 14;
      left: auto;
      right: 0;
      margin-left: 0;
      margin-right: -$pad * 15;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .decoPanel {
        max-height: 80vh;
      }
    }
  }
}
