@font-face {
  font-family: "pix Chicago";
  src: url("/assets/fonts/pixChicago.woff2") format("woff2"),
    url("/assets/fonts/pixChicago.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/RobotoMono-Bold.woff2") format("woff2"),
    url("/assets/fonts/RobotoMono-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/RobotoMono-Regular.woff2") format("woff2"),
    url("/assets/fonts/RobotoMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
