.workSection {
  width: 100%;
  height: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    justify-content: center;

    & .projectCounter:last-of-type {
      order: -1;
    }
  }

  & .projectPage {
    margin-bottom: $pad * 8;
  }
}

.projectCounter {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  font-size: $pad * 2;
  padding: $pad $pad * 2;

  .defaultButton {
    display: none;
  }
}

.projectSlider {
  display: flex;
  flex: 2;
}

.projectPage {
  transition: transform 300ms;
}

.projectLink {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: $textColor;
  height: 100%;
  width: 100vw;
  padding: 0 $pad * 6;
}

.project {
  position: relative;
  height: 100%;
  flex: 2;

  &.panel::before {
    z-index: 0;
  }

  &.accentBarAfter::after {
    z-index: -4;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 70%;
    transform: translateX(-50%) scaleY(0);
    animation: barVerticalWork 300ms 300ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
      forwards;
  }
}

.rightProject {
  transform: translateX(-$pad * 12) scale(0.9);
}

.leftProject {
  transform: translateX($pad * 12) scale(0.9);
}

.projectTitle {
  position: absolute;
  z-index: 2;
  left: -$pad * 2 + $line/2;
  right: -$pad * 2 + $line/2;
  bottom: $pad * 3 + $line/2;

  &.panel {
    position: absolute;
  }

  & .defaultButtonHolder {
    position: absolute;
    left: $pad + $line * 0.5;
  }

  & .defaultButton {
    display: inline-block;
  }

  & header p {
    margin-bottom: $pad;
    animation: glitchFadeIn 300ms 500ms ease forwards;
    opacity: 0;
    font-weight: normal;
  }

  & header h3,
  h2,
  h4,
  h5 {
    position: relative;
    animation: glitchFadeIn 300ms 400ms ease forwards;
    opacity: 0;
  }

  & header h3::after,
  h2::after,
  h4::after,
  h5::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -15%;
    height: 60%;
    width: 100%;
    background-image: url("../assets/img/crossPatern.png");
    opacity: 0;
    animation: glitchFadeInKwarter 500ms 500ms ease forwards;
  }
}

.projectImage {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;

  .image {
    position: relative;
    height: 100%;
    width: 100%;
  }

  & img {
    position: absolute;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.categoryDeco {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .workSection {
    padding-top: 7.1rem;

    & .projectPage {
      margin-bottom: $pad * 8;
    }
  }

  .projectCounter {
    padding: $pad * 4 0;

    & .defaultButton {
      display: block;
      margin: 0 $pad * 3;
      padding-left: $pad * 3;
      padding-right: $pad * 3;
    }

    & .defaultButton.second {
      transform: scaleX(-1);
    }
  }

  .project {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    &.accentBarAfter::after {
      top: 10%;
      left: auto;
      right: 22%;
      bottom: 10%;
      width: 100%;
    }
  }

  .rightProject {
    transform: translateX(0) scale(0.9);
  }

  .leftProject {
    transform: translateX(0) scale(0.9);
  }

  .projectTitle {
    position: relative;
    display: inline-block;
    align-self: center;
    width: auto;
    min-width: $pad * 70;
    right: auto;
    left: auto;
    bottom: auto;
    margin-left: $pad * 5;

    &.panel {
      position: relative;
      padding: $pad * 8 $pad * 5 + $line * 0.5 0;
    }

    & .defaultButtonHolder {
      bottom: -$pad * 3;
      left: $pad * 5 + $line * 0.5;
    }

    & header h3 {
      font-size: $pad * 5;
      width: auto;
    }

    & header p {
      font-size: $pad * 3;
      width: auto;
      margin-bottom: $pad * 5;
    }
  }

  & .projectImage {
    max-width: 50vw;

    &.panel {
      padding: $pad * 8 $pad * 5 $pad * 5;
    }
  }

  .categoryDeco {
    position: absolute;
    top: -$pad * 16;
    left: -$pad * 9;
    display: block;
    width: $pad * 28;
    height: $pad * 22;

    &.panel {
      position: absolute;
    }

    & div {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
    }
  }

  .categoryDecodesign div {
    background-image: url(/assets/img/designIcon.svg);
  }

  .categoryDeco3D div {
    background-image: url(/assets/img/3dIcon.svg);
  }

  .categoryDecodev div {
    background-image: url(/assets/img/codeIcon.svg);
  }

  .categoryDecomotion div {
    background-image: url(/assets/img/motionIcon.svg);
  }
}
