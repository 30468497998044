@import "fonts.scss";
@import "vars.scss";
@import "animations.scss";
@import "home.scss";
@import "work.scss";
@import "about.scss";
@import "contact.scss";
@import "workDetails.scss";
@import "nav.scss";

::selection {
  color: $accentTextColor;
  background: $accentColor;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: $textColor;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
dt,
nav {
  font-family: "pix Chicago", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
  letter-spacing: 0.2rem;
}

dd,
dl {
  margin: 0;
}

h2,
h3,
h4,
h5 {
  font-size: $pad * 3;
  letter-spacing: 0.4rem;
}

dt {
  font-size: $pad * 2;
  letter-spacing: 0.4rem;
}

p,
dl {
  font-family: "Roboto Mono", Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: $pad * 2;
  line-height: 2.8rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
}

a {
  color: $textColor;
  font-weight: bold;
  display: inline-block;
}

blockquote {
  position: relative;
  margin: $pad 0 $pad * 2;
  font-weight: bold;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: -$pad;
    left: -$pad;
    right: -$pad;
    bottom: -$pad;
    background-color: $accentTextColor;
    box-shadow: $line $line 0 $accentBar;
    border: $line solid $accentColor;
  }
}

strong {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: solid $line rgba($color: $accentColor, $alpha: 0.5);
  }
}

button {
  display: block;
  cursor: pointer;
}

button:focus {
  outline: none;
}

body {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  background-color: $bgColor;
  margin: 0;
  font-family: "Roboto Mono", Arial, Helvetica, sans-serif;
}

.mainHeader {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: $line solid $textColor;

  & .title {
    color: $textColor;
    text-decoration: none;
    display: none;
  }
}

.mainPage {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

main {
  position: relative;
  order: -1;
  flex: 2;
  overflow: hidden;
  margin-bottom: 5.5rem;

  &::after {
    content: "";
    display: block;
    position: absolute;

    top: 30%;
    width: 100%;
    height: 40%;
    background-color: rgba($color: $accentColor, $alpha: 0.5);
    background-image: url("../assets/img/crossPatern.png");
    opacity: 0.4;
  }
}

.mainHolder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

h1 {
  display: none;
}

.pixFont {
  font-family: "pix Chicago", Arial, Helvetica, sans-serif;
  font-weight: normal;
  letter-spacing: 0.2rem;
  text-decoration: none;
  color: $textColor;
}

.borderBox {
  border: $line solid $textColor;
  box-shadow: $line $line 0 rgba($color: $accentColor, $alpha: 0.5);
}

.defaultButton {
  position: relative;
  display: block;
  background-color: $accentColor;
  font-family: "pix Chicago", Arial, Helvetica, sans-serif;
  font-weight: normal;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-decoration: none;
  color: $accentTextColor;
  padding: $pad $pad * 2;
  text-align: center;
  font-size: 1.2rem;
  border: solid $line $accentColor;
  overflow: hidden;
  transition: all 150ms;
  cursor: pointer;
  animation: glitchFadeIn 300ms 300ms forwards;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: $pad/2;
    left: $pad/2;
    right: $pad/2;
    bottom: $pad/2;
    border: solid $line/2 $accentColor;
    transform: scale(1.2, 1.2);
    transition: all 300ms;
  }

  &:hover {
    background-color: $accentTextColor;
    color: $accentColor;
    animation: flickerButtonBg 150ms forwards;
  }

  &:hover::after {
    transform: scale(1);
  }

  &:active {
    background-color: $accentTextColor;
    transform: scale(0.95);
  }

  &:active::after {
    transform: scale(1.5, 1.5);
  }
}

.forground {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & .noiseAnimated {
    z-index: 1;
    background-image: url("../assets/img/noiseTexture.png");
    opacity: 0.1;
    animation: noiseAnim 5s steps(10) forwards infinite;
  }

  & .noise {
    z-index: 1;
    background-image: url("../assets/img/noiseTexture.png");
    opacity: 0.15;
  }

  & .brightness {
    z-index: 2;
    background-color: #fff;
    opacity: 0.5;
  }

  & .scanLines {
    z-index: 3;
    background-image: url("../assets/img/scanLineTexture.png");
    opacity: 0.4;
    animation: scanLineAnim 40s linear infinite;
  }
}

.panel,
.decoPanel {
  position: relative;
  border: solid $line $accentColor;
  padding: $pad * 5 $pad * 2 $pad * 2;
  background-color: $accentTextColor;
  box-shadow: $line $line 0 rgba($color: $accentColor, $alpha: 0.5);

  &::before {
    content: "";
    position: absolute;
    top: -$line;
    left: -$line;
    right: -$line;
    display: block;
    height: $pad * 3;
    background-color: $accentColor;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/scanLineTexture.png");
    opacity: 0.4;
    pointer-events: none;
  }
}

.pannelHolder {
  position: relative;
}

.decoPanel {
  position: absolute;
  z-index: -1;
}

.linkButton {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: $pad * 6 + $line;
  font-size: $pad * 2;
  color: $textColor;
  text-decoration: none;
  background: none;
  border: solid $line $textColor;
  transition: all 150ms;

  &::before {
    content: "";
    position: absolute;
    left: -$line;
    top: -$line;
    bottom: -$line;
    display: block;
    width: $pad * 6 + $line;
    background-color: $textColor;
    transition: all 150ms;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.behance::before {
    background-image: url(/assets/img/behanceLogo.svg);
    background-size: 90%;
  }

  &.artstation::before {
    background-image: url(/assets/img/artstationLogo.svg);
    background-size: 60%;
  }

  &.linkedIn::before {
    background-image: url(/assets/img/linkedinLogo.svg);
    background-size: 60%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: $pad * 6 - $line;
    border: solid $line $accentTextColor;
    //background-color: $textColor;
    opacity: 0;
  }

  &:hover::after {
    animation: glitchFadeIn 150ms forwards;
  }

  & span {
    display: block;
    margin: 0 $pad * 2;
    margin-left: $pad * 8;
    text-transform: uppercase;
    font-weight: bold;
  }

  &:hover span {
    color: $accentTextColor;
  }

  & span::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $accentColor;
    transform: scaleX(0);
    transform-origin: center left;
    transition: all 300ms;
  }

  &:hover span::before {
    transform: scaleX(1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.accentBarAfter {
  position: relative;
  z-index: 2;

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    max-width: $pad * 50;
    background-color: $accentBar;
    background-image: url("../assets/img/crossPaternFade.png");
  }
}

.page404 {
  width: 100%;
  height: 100%;

  & .panel {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    text-align: center;
    transform: translate(-50%, -50%);

    & header {
      margin-bottom: $pad * 2;
    }
  }
}

@media only screen and (min-width: 1070px) {
  main {
    order: 1;
    margin-bottom: 0;
  }

  h1 {
    display: block;
  }

  h2 {
    font-size: $pad * 5;
  }

  .mainHeader {
    bottom: auto;
    top: 0;
    display: flex;
    align-items: center;
    background: none;

    & .title {
      display: block;
    }

    & h1 {
      display: flex;
      align-items: center;
    }

    & h1::before {
      content: "";
      display: block;
      width: $pad * 5;
      height: $pad * 5;
      background-image: url(../assets/img/logo.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: $pad * 3;
      margin-right: $pad;
    }

    & nav {
      flex: 2;
    }
  }
}

/* HIDE */
.hide {
  display: none;
}
