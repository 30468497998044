.contactArticle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: $pad * 3;
  min-height: 100%;

  &.accentBarAfter {
    z-index: 2;

    &::after {
      top: $pad * 10;
      left: 35%;
      right: 10%;
      height: 75vh;
      width: auto;
      max-width: $pad * 40;
      transform: scaleY(0);
      animation: barVertical 300ms 200ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
        forwards;
    }
  }

  & h2 {
    position: relative;
    display: inline-block;
    margin-top: $pad * 3;
    animation: glitchFadeIn 300ms 300ms ease forwards;
    opacity: 0;
  }

  & h2::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -15%;
    height: 60%;
    width: 100%;
    background-image: url("../assets/img/crossPatern.png");
    opacity: 0;
    animation: glitchFadeInKwarter 500ms 500ms ease forwards;
  }

  & .letter {
    position: relative;
    left: -24%;
    order: -1;
    width: $pad * 21;
    height: $pad * 17;
    margin-bottom: -$pad * 6;
    margin-left: -$pad * 2;

    & .letterImg {
      width: 100%;
      height: 100%;
      background-image: url("../assets/img/inbox.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      animation: glitchFadeIn 300ms 500ms ease forwards;
      opacity: 0;
    }
  }

  & p {
    margin-bottom: $pad * 3;
    animation: glitchFadeIn 300ms 500ms ease forwards;
    opacity: 0;
  }
}

.contactMain {
  margin-bottom: $pad * 4;
  max-width: $pad * 60;
}

.socialMediaSection {
  max-width: $pad * 60;
  width: 100%;

  & header {
    margin-bottom: $pad * 3;
    animation: glitchFadeIn 300ms 500ms ease forwards;
    opacity: 0;
  }
}

.socialLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0;

  & li {
    margin-left: 0;
    margin-bottom: $pad * 3;
    animation: glitchFadeIn 300ms 600ms ease forwards;
    opacity: 0;
  }

  & li:nth-of-type(2) {
    animation-delay: 650ms;
  }

  & li:nth-of-type(3) {
    animation-delay: 700ms;
  }

  & li:nth-of-type(4) {
    animation-delay: 750ms;
  }

  & li:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1070px) {
  .contactArticle {
    display: grid;
    grid-template-columns: 1fr min-content min-content 1fr;
    grid-template-rows: 1fr min-content min-content 1fr;
    gap: $pad * 4;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -$pad * 4;
    padding-top: 7.1rem;

    & .letter {
      left: auto;
      order: 0;
      margin: 0;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      width: 113%;
      height: $pad * 30;
      padding: $pad * 9 $pad * 3 $pad * 6;
    }

    & .contactMain {
      margin: 0;
      grid-column: 3 / 4;
      grid-row: 2 / -2;
      min-width: $pad * 81;
      padding: $pad * 9 $pad * 3 $pad * 6;
      align-self: center;

      & p {
        font-size: $pad * 3;
      }

      & .defaultButton {
        display: inline-block;
      }
    }

    &.accentBarAfter {
      &::after {
        top: 20%;
        left: 50%;
        right: 20%;
        height: 50%;
        width: auto;
        max-width: $pad * 70;
        transform: translateX(-60%) scaleY(0);
        animation: barVerticalContact 300ms 300ms
          cubic-bezier(0.57, 0.35, 0.79, 1.08) forwards;
      }
    }

    & .socialMediaSection {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      min-width: $pad * 44;
      padding: $pad * 6 $pad * 3 $pad * 3;
    }
  }
}
