.workDetail {
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: $accentTextColor;
  animation: fadeIn 300ms ease forwards;
  opacity: 0;

  & .projectImage {
    animation: fadeIn 300ms 400ms ease forwards;
    opacity: 0;
  }

  & .project.accentBarAfter::after {
    transform: none;
    display: none;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  &::before {
    background-image: url("../assets/img/scanLineTexture.png");
    opacity: 0.4;
    animation: scanLineAnim 40s linear infinite;
  }

  &::after {
    background-image: url("../assets/img/noiseTexture.png");
    opacity: 0.05;
    animation: noiseAnim 5s steps(10) forwards infinite;
  }

  & .wrapper {
    margin: 0 auto;
    max-width: $pad * 80;
  }
}

.workDetailArticle {
  position: relative;
  z-index: 2;

  & .projectPage {
    height: 70vh;
    margin: $pad * 3 auto $pad * 8;
    max-width: $pad * 210;
    padding: 0 $pad * 3;
  }
}

.projectTitle {
  left: $pad * 2;
  right: $pad * 2;
  bottom: -$pad * 3;
}

.workDetailSection {
  & h3,
  & h4 {
    font-size: $pad * 2;
  }
  margin: $pad * 5 $pad * 2 0;
}

.workDetailSection:last-of-type {
  margin-bottom: $pad * 10;
}

.rollInformation {
  text-align: center;

  & .panel {
    margin-bottom: $pad * 3;
    max-width: $pad * 70;
    margin: 0 auto $pad * 3;
  }

  & .panel:last-of-type {
    margin-bottom: 0;
  }

  &.accentBarAfter::after {
    top: $pad * 2;
    left: 15%;
    width: 70%;
    max-width: 70%;
    bottom: $pad * 2;
    animation: barVertical 300ms 300ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
      forwards;
    transform: scaleY(0);
  }
}

.projectInfo,
.projectInfoImage {
  & header {
    margin-bottom: $pad;
  }

  & p {
    margin-bottom: $pad * 2;
  }

  & .accentBarAfter::after {
    z-index: -50;
    top: 0;
    height: 100%;
  }
}

.projectInfoImage {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 0;

  & header {
    margin-top: $pad * 9;
  }

  & .imageHolder {
    z-index: 2;
    max-width: $pad * 30;
    height: $pad * 25;
    margin-left: -$pad;
    margin-bottom: -$pad * 6;

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  & .accentBarAfter {
    z-index: 1;
  }

  & .accentBarAfter::after {
    top: -$pad * 14;
    bottom: auto;
    left: 15%;
    width: 50%;
    height: $pad * 22;
  }
}

.screenshotSection {
  & .imageHolder {
    height: 100%;
    width: 100%;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.screenshotSectionaccent {
  max-width: $pad * 70;
  margin: 0 auto;

  &.fullWidth {
    max-width: 100vh;
  }
}

.screenshotSectionaccent.accentBarAfter::after {
  top: -$pad * 3;
  bottom: 50%;
  left: -$pad * 5;
  max-width: $pad * 40;
}

.workDetailNextMove {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 $pad * 2 $pad * 5;

  &.accentBarAfter::after {
    height: 100%;
    top: -$pad * 2;
    left: $pad * 3;
    max-width: 80%;
  }

  & h3 {
    font-size: $pad * 2;
    text-align: left;
  }

  & .nextTitle {
    padding-top: $pad * 7;
    padding-bottom: $pad * 4;
  }

  & section {
    width: 100%;
    margin-top: $pad * 3;

    & h4,
    & h5 {
      font-size: $pad * 3;
    }
  }

  & .contactLink {
    padding-top: $pad * 9;
    padding-bottom: $pad * 6;
    & header::after {
      content: "";
      display: block;
      height: 35vw;
      width: 40vw;
      max-width: $pad * 25;
      max-height: $pad * 25;
      margin: $pad * 3 auto;
      background-image: url("../assets/img/inbox.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  & .orWrapper {
    align-self: flex-start;
  }

  & .OR {
    position: relative;
    z-index: 11;
    font-size: $pad * 4;

    padding: $pad * 8 $pad * 5 $pad * 5;
    margin-top: -$pad * 3;
    margin-left: -$pad * 2;
    margin-bottom: -$pad * 5;
  }
}

.contactLinkAndOr {
  display: flex;
  flex-direction: column;
}

.nextProjectPanel {
  position: relative;
  z-index: 10;
  margin-bottom: -$pad * 5;
}

.nextProject {
  display: block;
  color: $textColor;
  text-align: left;
  max-width: $pad * 210;
  margin-bottom: $pad * 5;

  & .projectImage {
    height: 70vh;
  }

  & .imageHolder {
    margin-top: $pad * 2;
    height: 70vh;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & p {
    margin-bottom: $pad;
    font-weight: normal;
  }

  & .defaultButtonHolder {
    margin-left: $pad;
  }
}

@media only screen and (min-width: 1024px) {
  .workDetail {
    padding-top: 7.1rem;

    & .panel,
    &.panel {
      padding: $pad * 8 $pad * 5 $pad * 5;
    }

    & .contactLink.panel {
      padding-top: $pad * 10;
      padding-bottom: $pad * 10;
    }

    & h3,
    & h4,
    & h5 {
      font-size: $pad * 3;
    }

    & .projectTitle p {
      margin-bottom: 0;
      font-size: $pad * 3;
    }

    & .linkButton {
      display: inline-flex;
    }

    & .projectPage {
      height: 70vh;
      margin: $pad * 5 auto $pad * 25;
      padding: 0 $pad * 5;
    }

    & .project {
      margin: 0;

      &.accentBarAfter::after {
        top: 80%;
        left: calc(50% - 44rem);
        right: auto;
        bottom: -$pad * 17;
        width: 100%;
        animation: barVertical 300ms 500ms cubic-bezier(0.57, 0.35, 0.79, 1.08)
          forwards;
        transform: scaleY(0);
        display: block;
      }

      & .projectImage {
        width: 100%;
        max-width: 100vw;
      }

      & .projectTitle {
        position: absolute;
        bottom: 0;
        left: 50%;
        max-width: $pad * 80;
        margin-left: $pad * 14;
        transform: translate(-50%, 50%);
      }

      & .categoryDeco {
        top: 50%;
        left: $pad * 2;
        transform: translate(-100%, -50%);

        & .panel {
          height: 100%;
          padding: $pad * 5 $pad * 2 $pad * 2;
        }

        & div {
          height: 100%;
        }
      }
    }

    & .wrapper {
      max-width: 100vw;
    }
  }

  .workDetailSection {
    margin: $pad * 20 $pad * 5 0;
  }

  .workDetailSection:last-of-type {
    margin-bottom: $pad * 20;
  }

  .projectInfo {
    max-width: $pad * 80;
    margin-left: $pad * 20;
  }

  .rollAndInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $pad * 10 0;

    & .workDetailSection {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .rollInformation {
    margin-right: $pad * 13;

    & .panel {
      position: relative;
      z-index: 2;
      padding: $pad * 13 $pad * 10 $pad * 10;
    }

    & .panel:nth-of-type(2n) {
      position: relative;
      left: $pad * 26;
      margin-top: -$pad * 10;
      margin-bottom: -$pad * 5;
    }

    &.accentBarAfter::after {
      top: -$pad * 4;
      width: 100%;
      max-width: $pad * 50;
      bottom: -$pad * 4;
    }
  }

  .screenshotMain {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content;
    column-gap: $pad * 10;
    row-gap: $pad * 20;
    width: 100%;
    max-width: $pad * 210;
    padding: 0 $pad * 5;
    margin: $pad * 20 auto 0;

    .workDetailSection {
      margin: 0;
    }
  }

  .projectInfoImage {
    grid-column: 1 / -1;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    & .info {
      max-width: $pad * 80;
      margin-bottom: -$pad * 3;
      margin-left: $pad * 10;
    }

    & .imageHolder {
      max-width: $pad * 120;
      width: 50vw;
      height: 70vh;
      margin: 0;
    }

    & .accentBarAfter::after {
      top: 50%;
      left: -$pad * 15;
      bottom: 0;
      width: $pad * 20;
      height: 50vh;
      margin-top: calc(-10vh - 3.2rem);
      transform: translateY(-50%);
    }
  }

  .screenshotSectionaccent {
    width: 100%;
    align-self: center;
    justify-self: flex-end;
    max-width: $pad * 100;
    margin: 0;

    &.fullWidth {
      margin: 0 auto;
      max-width: $pad * 210;
      grid-column: 1 / -1;
    }
  }

  .screenshotSection {
    width: 100%;
    height: 80vh;
    margin-left: 0;
    margin-right: 0;
  }

  .screenshotSectionaccent.accentBarAfter::after {
    top: -$pad * 5;
    bottom: -$pad * 5;
    left: calc(50% - 8rem);
    max-width: $pad * 60;
    transform: translateX(-50%);
  }

  .screenshotSectionaccent:nth-of-type(2n).accentBarAfter::after,
  .screenshotSectionaccent:nth-of-type(3n).accentBarAfter::after {
    top: -$pad * 5;
    bottom: $pad * 10;
    left: -$pad * 3;
    transform: none;
    max-width: $pad * 40;
  }

  .screenshotSectionaccent.accentBarAfter:first-of-type::after,
  .screenshotSectionaccent:nth-of-type(4n).accentBarAfter::after {
    top: -$pad * 5;
    bottom: -$pad * 5;
    left: calc(50% - 8rem);
    max-width: $pad * 45;
    transform: translateX(-50%);
  }

  .screenshotSectionaccent:last-of-type {
    margin-bottom: 0;
  }

  .workDetailNextMove {
    justify-content: center;
    max-width: $pad * 210;
    margin: $pad * 10 auto 0;
    row-gap: $pad * 5;
    column-gap: $pad * 5;
    padding: $pad * 5 $pad * 5 0;

    & .nextTitle {
      align-self: center;
    }

    & .orWrapper {
      position: relative;
      align-self: center;

      &::before,
      &::after {
        content: "<<<<<";
        display: block;
        position: absolute;
        font-size: $pad * 4;
      }

      &::before {
        top: 50%;
        left: -$pad * 13;
        transform: translateY(-50%) rotate(-90deg);
      }

      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    & .OR {
      margin: 0;
      font-size: $pad * 5;
      padding: $pad * 12 $pad * 9 $pad * 9;
    }

    & section,
    & .contactLinkBar,
    & .nextProjectBar {
      display: flex;
      flex-direction: column;
      margin: 0;

      & h4 {
        font-size: $pad * 5;
      }

      & .defaultButton {
        align-self: center;
      }
    }

    &.accentBarAfter::after {
      top: 0;
      left: 30%;
      width: $pad * 40;
      height: 70%;
    }
  }

  .contactLinkAndOr {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: $pad * 210;
  }

  section.contactLink {
    max-width: $pad * 80;
    margin-right: $pad * 20;
    margin-left: -10%;

    & .defaultButton {
      font-size: $pad * 2;
      padding: $pad * 2 $pad * 3;
    }
  }

  .workDetailNextMove .nextProjectSection {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: $pad * 5;
  }

  .nextProjectPanel {
    grid-column: -3 / -1;
    margin-bottom: -$pad * 10;
    margin-top: -$pad * 10;
  }

  .nextProject {
    color: $textColor;
    text-align: left;
    grid-column: 1 / -1;
    margin-bottom: 0;

    & .projectTitle {
      left: 0;
      right: 0;
      bottom: $pad * 5;
      margin: 0 $pad * 3;
      grid-column: -3 / -1;
      min-width: $pad * 70;

      & p {
        font-size: $pad * 3;
      }

      & h5 {
        font-size: $pad * 5;
      }
    }

    & .projectTitle.panel {
      position: absolute;
    }

    & .imageHolder {
      margin: $pad * 5 0 0;
    }

    & .defaultButtonHolder {
      left: $pad * 4 + $line / 2;
    }

    .categoryDeco {
      padding: $pad * 5 $pad * 2 $pad * 2;
    }
  }
}
