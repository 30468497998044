nav {
  background-color: $accentTextColor;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/scanLineTexture.png");
    opacity: 0.4;
    pointer-events: none;
  }

  & ul {
    list-style: none;
    display: flex;
    justify-content: center;
  }

  & li {
    position: relative;
    display: flex;
    align-items: center;
  }

  & li a {
    text-decoration: none;
    color: $textColor;
    font-size: 1rem;
    display: block;
    padding: $pad * 2 $pad/2;
    font-weight: normal;
  }

  & li a::after,
  & li a::before {
    content: "_";
    opacity: 0;
  }
}

.activeElement {
  &::after {
    opacity: 1;
    animation: fadeElem 1s infinite;
  }

  &:hover::after {
    animation: none;
    opacity: 0;
  }
}

.returnToWork {
  display: block;
  background-color: $accentColor;
  color: $accentTextColor;
  font-size: $pad * 2;
  text-align: center;
  padding: $pad * 2;
}

@media only screen and (min-width: 1070px) {
  nav {
    display: flex;
    justify-content: flex-end;
    background: none;

    & ul {
      padding-right: $pad * 3;
    }

    &::after {
      display: none;
    }

    & li {
      margin: 0;
    }

    & li a {
      margin: 0;
      padding: $pad * 3 $pad;
    }
  }

  .returnToWork {
    width: 100%;
    padding: 0;
  }
}

@keyframes fadeElem {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
