@keyframes glitchFadeIn {
  0% {
    opacity: 0;
  }

  23% {
    opacity: 0.8;
  }

  46% {
    opacity: 0;
  }

  70% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes barVertical {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes barVerticalWork {
  0% {
    transform: translateX(-50%) scaleY(0);
  }

  100% {
    transform: translateX(-50%) scaleY(1);
  }
}

@keyframes barVerticalContact {
  0% {
    transform: translateX(-60%) scaleY(0);
  }

  100% {
    transform: translateX(-60%) scaleY(1);
  }
}

@keyframes glitchFadeInHalf {
  0% {
    opacity: 0;
  }

  23% {
    opacity: 0.4;
  }

  46% {
    opacity: 0;
  }

  70% {
    opacity: 0.15;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes glitchFadeInKwarter {
  0% {
    opacity: 0;
  }

  23% {
    opacity: 0.2;
  }

  46% {
    opacity: 0;
  }

  70% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes noiseAnim {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 10% 10%;
  }

  70% {
    background-position: -10% 7%;
  }

  100% {
    background-position: 0;
  }
}

@keyframes scanLineAnim {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 10vh;
  }
}

@keyframes flickerButtonBg {
  0% {
    background-color: $accentColor;
  }

  30% {
    background-color: $accentTextColor;
  }

  50% {
    background-color: $accentColor;
  }

  100% {
    background-color: $accentTextColor;
  }
}

@keyframes arrowAnim {
  0% {
    transform: scale(0) rotate(45deg);
  }

  100% {
    transform: scale(1) rotate(45deg);
  }
}

@keyframes homeLineAnim {
  0% {
    transform: translateY(-50%) scaleX(0);
  }

  100% {
    transform: translateY(-50%) scaleX(1);
  }
}

@keyframes scaleAnim {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes defaultButtonIntro {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
